import React from "react";

// css
import styles from "./ImagesComponent.module.css";

const ImagesComponent = (props) => {
  // load all images from local folder
  // display all images

  return (
    <div className={styles.images}>
      <ul
        className={`grid-box`}
        style={{
          "--p-grid-item-size": "10em",
          "--p-grid-item-size-small-screens": "10rem",
          "--grid-gap": "0.5rem",
        }}
      >
        {/* iterate over all image names and display the image */}
        {props.imageNames.map((imageName, index) => {
          return (
            <li key={index}>
              <label
                className={`card is-allow-focus u-cursor-pointer ${styles.imageCard}`}
                style={{
                  "--card-padding": "0rem",
                  "--card-border-radius": "var(--border-radius-small)",
                }}
              >
                {/* image */}
                <div className={`card-image`}>
                  <img
                    className={`${styles.productImage}`}
                    // if the image is not found, use a placeholder
                    src={require(`../product_images/${imageName}`)}
                    alt={imageName}
                  />
                </div>
                {/* content */}
                <div className={`card-content ${styles.card_content}`}>
                  <div className="content">
                    <p className={`${styles.imageName}`}>{imageName}</p>
                  </div>
                </div>
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ImagesComponent;
