import { functions } from "../appwriteConfig";

const getProducts = (setProducts) => {
  const promise = functions.createExecution(
    process.env.REACT_APP_GET_PRODUCTS_FUNCTION_ID
  );
  promise.then(
    function (response) {
      console.log("getProducts");
      // parse products from response
      const products = JSON.parse(response.response).products;
      // convert products to object with id as key
      const productsObject = {};
      // iterate over all values of product dictionary
      for (const [key, value] of Object.entries(products)) {
        // add id to product object
        // add product to products object
        productsObject[value.id] = value;
      }
      setProducts(productsObject);
    },
    function (error) {
      console.log(error);
      return {};
    }
  );
};

export default getProducts;
