import React, { useState, useRef, useEffect } from "react";

// css
import styles from "./InvoiceComponent.module.css";

// components
import InvoiceArticle from "./InvoiceComponents/InvoiceArticle";

const InvoiceComponent = (props) => {
  const [length, setLength] = useState(0); // length of the invoice
  const [totalPrice, setTotalPrice] = useState(""); // total price of the invoice
  const scrollRef = useRef(null);

  useEffect(() => {
    if (props.invoice.length && length < props.invoice.length) {
      scrollRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
    setLength(props.invoice.length);
  }, [props.invoice.length, length]);

  useEffect(() => {
    // calculate the total price
    let price = 0;
    props.invoice.forEach((item) => {
      price += item[0].price * item[1];
    });
    // convert prize to float with two decimals
    let price_str = price.toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    // add the currency
    price_str += " €";
    setTotalPrice(price_str);
  }, [props.invoice]);

  return (
    <div className={styles.invoiceBase}>
      {/* <div className={styles.scroller}> */}
      <div className={styles.invoiceArticles}>
        <ul
          className={`grid-box`}
          style={{
            "--grid-gap": "0.3rem",
            "--p-grid-item-size": "20em",
          }}
        >
          {/* map through all products and render them, also get the index */}

          {props.invoice.map((invoiceItem, index) => {
            return (
              <InvoiceArticle
                article={invoiceItem[0]}
                amount={invoiceItem[1]}
                index={index}
                increaseAmount={props.increaseAmount}
                decreaseAmount={props.decreaseAmount}
                key={index}
              />
            );
          })}
        </ul>
        <div ref={scrollRef} />
      </div>
      <div className={styles.invoiceMenu} onClick={props.createInvoice}>
        <p className={styles.invoiceTotalText}>Bezahlen:</p>
        <p className={styles.invoiceTotalPrice}>{totalPrice}</p>
      </div>
    </div>
  );
};

export default InvoiceComponent;
