import React, { useState, useEffect } from "react";

// css
import styles from "./ProductCard.module.css";

// utils
import { changeFavorite } from "../../appwrite-utils/favorite";

const ProductCard = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const [favorite, setFavorite] = useState(props.product.favorite || false);

  // set favorite when props change
  useEffect(() => {
    setFavorite(props.product.favorite || false);
  }, [props.product]);

  // try to get the image
  let image = null;
  try {
    image = require(`../../product_images/${props.imageName}`);
  } catch (err) {
    try {
      image = require(`../../product_images/stock/${props.product.category}.png`);
    } catch (err) {
      image = require(`../../product_images/stock/article.png`);
    }
  }

  const callMenu = (e) => {
    e.preventDefault();
    setShowMenu(true);
  };

  const handleLabelBlur = (event) => {
    setShowMenu(false);
  };

  const triggerFavoriteChange = () => {
    console.log("change favorite");
    favorite ? setFavorite(false) : setFavorite(true);
    changeFavorite(props.product.id, !props.product.favorite);
  };

  return (
    <li
      tabIndex={0}
      onClick={() => {
        if (props.product.stock <= 0) return;
        if (!showMenu) props.addToInvoice(props.product);
      }}
      onContextMenu={(e) => callMenu(e)}
      // set showMenu to false when the card loses focus
      onBlur={handleLabelBlur}
    >
      <label
        className={`card is-allow-focus u-cursor-pointer ${
          styles.productCard
        } ${props.product.stock <= 0 ? styles.disabled : ""} ${
          showMenu && styles.showMenu
        }`}
        style={{
          "--card-padding": "0rem",
          "--card-border-radius": "var(--border-radius-small)",
        }}
      >
        {/* image */}
        <div className={`card-image`}>
          <img
            className={`${styles.product_image}`}
            // if the image is not found, use a placeholder
            src={image}
            alt={props.product.name}
          />
        </div>
        {/* content */}
        <div className={`card-content ${styles.card_content}`}>
          <div className="content">
            <p className={`${styles.product_name}`}>{props.product.name}</p>
          </div>
        </div>
        <div className={`${styles.stock_counter}`}>
          <p>{props.product.stock}</p>
        </div>
        {favorite && !showMenu && (
          <div className={`${styles.favorite}`}>
            <span
              className={`icon-star ${styles.favorite_icon}`}
              aria-hidden="true"
            ></span>
          </div>
        )}
        {showMenu && (
          <div
            className={`${styles.favorite} ${styles.favorite_menu}`}
            onClick={() => triggerFavoriteChange()}
          >
            <span
              className={`icon-star ${
                favorite ? styles.favorite_icon : styles.not_favorite_icon
              }`}
              aria-hidden="true"
            ></span>
          </div>
        )}
      </label>
    </li>
  );
};

export default ProductCard;
