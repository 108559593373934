import React from "react";
// import navigation
import { Link, useNavigate } from "react-router-dom";

// css
import styles from "./MenuComponent.module.css";

const SideBar = (props) => {
  return (
    <div className={`${styles.sidebar} ${props.isOpen && styles.sidebarOpen}`}>
      <a href="/invoice" className={`button ${styles.sideMenuButton}`}>
        Kasse
      </a>
      <a href="/favorites" className={`button ${styles.sideMenuButton}`}>
        Favoriten
      </a>
      <a href="/images" className={`button ${styles.sideMenuButton}`}>
        Bilder
      </a>
      <button className={`button ${styles.sideMenuButton}`}>Refresh</button>
      <button
        className={`button ${styles.sideMenuButton} ${styles.logoutButton}`}
        onClick={() => props.handleLogout()}
      >
        Logout
      </button>
    </div>
  );
};
export default SideBar;
