import { functions } from "../appwriteConfig";

export const changeFavorite = async (id, fav_state) => {
  const promise = functions.createExecution(
    process.env.REACT_APP_ADD_FAVORITE_FUNCTION_ID,
    // data has to be a string
    JSON.stringify({ article_id: id, favorite: fav_state })
  );
  promise.then(function (response) {
    console.log(response);
  });
};
