import { Client, Account, Databases, Functions } from "appwrite";

const client = new Client()
  .setEndpoint(process.env.REACT_APP_APPWRITE_ENDPOINT)
  .setProject(process.env.REACT_APP_APPWRITE_PROJECT_ID);

export const account = new Account(client);
export const databases = new Databases(client);
export const functions = new Functions(client);

// subscribe to product database
export const subscribeToProductDatabase = (setProducts) => {
  const subscription = client.subscribe(
    `databases.${process.env.REACT_APP_APPWRITE_DATABASE_ID}.collections.${process.env.REACT_APP_APPWRITE_COLLECTION_ID}.documents`,
    (response) => {
      const changedProduct = response.payload;
      setProducts((prevProducts) => {
        const newProducts = { ...prevProducts };
        newProducts[changedProduct.id] = changedProduct;
        return newProducts;
      });
    }
  );
  return () => {
    subscription();
  };
};

export default client;
