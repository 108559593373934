import React, { useState, useEffect, useRef } from "react";
import "../index.css";

// css
import styles from "./BaseComponent.module.css";

// components
import MenuComponent from "./MenuComponent";
import ProductComponent from "./ProductComponent";
import InvoiceComponent from "./InvoiceComponent";
import Images from "../pages/Images";

// utils
import getProducts from "../appwrite-utils/getProducts";
import { createNewInvoice } from "../appwrite-utils/invoice";
import { subscribeToProductDatabase } from "../appwriteConfig";
import { printInvoice } from "../utils/Printer";

const BaseComponent = ({ content }) => {
  const [products, setProducts] = useState({});
  const [imageNames, setImageNames] = useState([]); // ["image1", "image2", ...]
  const [invoice, setInvoice] = useState([]);

  // printer
  const [connectionStatus, setConnectionStatus] = useState("Not Connected");
  const ePosDevice = useRef();
  const printer = useRef();
  const STATUS_CONNECTED = "Connected";

  useEffect(() => {
    // get all possible image names (they wont change during runtime)
    const importImages = (r) => {
      let names = [];
      r.keys().map((item, index) => {
        let imageName = item.replace("./", "");
        names.push(imageName);
      });
      // sort images based on the number of '+' in the name
      names.sort((a, b) => {
        return b.split("+").length - a.split("+").length;
      });
      return names;
    };
    const names = importImages(
      require.context("../product_images", false, /\.(png|jpe?g|svg)$/)
    );
    setImageNames(names);
    // initialize products
    getProducts(setProducts);
    // subscribe to product database
    subscribeToProductDatabase(setProducts);
  }, []);

  // subscript

  // ==================== CHANGE INVOICE FUNCTIONS ====================
  // add product to invoice
  const addToInvoice = (product) => {
    // check if the last item in the invoice is the same as the product
    if (invoice.length > 0) {
      // lastItem is a tuple of [product, amount]
      const lastItem = invoice[invoice.length - 1];
      if (lastItem[0].id === product.id) {
        // increase the amount
        const newInvoice = [...invoice];
        newInvoice[invoice.length - 1][1] += 1;
        setInvoice(newInvoice);
        return;
      }
    }
    // add the product to the invoice
    setInvoice([...invoice, [product, 1]]);
  };

  const increaseAmount = (index) => {
    // increase the amount of the product at the given index
    const newInvoice = [...invoice];
    newInvoice[index][1] += 1;
    setInvoice(newInvoice);
  };

  const decreaseAmount = (index) => {
    // decrease the amount of the product at the given index
    const newInvoice = [...invoice];
    if (newInvoice[index][1] === 1) {
      // remove the product from the invoice
      newInvoice.splice(index, 1);
    } else {
      newInvoice[index][1] -= 1;
    }
    setInvoice(newInvoice);
  };

  const createInvoice = async () => {
    let articlesDict = {};
    invoice.forEach((item) => {
      if (item[0].id in articlesDict) {
        articlesDict[item[0].id].quantity += item[1];
      } else {
        articlesDict[item[0].id] = {
          article: item[0],
          quantity: item[1],
        };
      }
    });
    console.log(articlesDict);
    // create the invoice
    let article_list = [];
    for (const [key, value] of Object.entries(articlesDict)) {
      article_list.push({
        article: value.article,
        quantity: value.quantity,
      });
    }

    // activate a loading screen
    // TODO

    // clear the invoice
    setInvoice([]);

    // create the invoice
    const invoice_response = await createNewInvoice(article_list);
    if (!invoice_response) {
      // show an error
      console.log("Error creating invoice");
      return;
    }
    // print the invoice
    printInvoice(invoice_response, printer);

    // deactivate the loading screen
    // TODO
  };

  // ==================================================================
  // ==================== PRINTER FUNCTIONS ====================
  const connectPrinter = (printerIPAddress, printerPort) => {
    setConnectionStatus("Connecting ...");
    if (!printerIPAddress) {
      setConnectionStatus("Type the printer IP address");
      return;
    }
    if (!printerPort) {
      setConnectionStatus("Type the printer port");
      return;
    }

    let ePosDev = new window.epson.ePOSDevice();
    ePosDevice.current = ePosDev;

    ePosDev.connect(printerIPAddress, printerPort, (data) => {
      if (data === "OK") {
        ePosDev.createDevice(
          "local_printer",
          ePosDev.DEVICE_TYPE_PRINTER,
          { crypto: true, buffer: false },
          (devobj, retcode) => {
            if (retcode === "OK") {
              printer.current = devobj;
              setConnectionStatus(STATUS_CONNECTED);
            } else {
              throw retcode;
            }
          }
        );
      } else {
        setConnectionStatus("Connection failed");
        throw data;
      }
    });
  };

  return (
    <div className="mainContainer">
      <MenuComponent
        connectionStatus={connectionStatus}
        connectPrinter={connectPrinter}
      />
      {content === "invoice" && imageNames.length > 0 && (
        <>
          {products && Object.keys(products).length > 0 && (
            <ProductComponent
              products={products}
              imageNames={imageNames}
              addToInvoice={addToInvoice}
            />
          )}
          <InvoiceComponent
            invoice={invoice}
            increaseAmount={increaseAmount}
            decreaseAmount={decreaseAmount}
            createInvoice={createInvoice}
          />
        </>
      )}
      {content === "favorites" && <div>favorites</div>}
      {content === "images" && <Images imageNames={imageNames} />}
    </div>
  );
};

export default BaseComponent;
