const LineWidthNormal = 47;
const LineWidthSmall = 57;
const addSpaces = (leftText, rightText, size) => {
  console.log(leftText);
  console.log(leftText.length);
  let lw = 0;
  if (size === "normal") {
    lw = LineWidthNormal;
  } else if (size === "small") {
    lw = LineWidthSmall;
  }
  if (rightText.length > lw) {
    return leftText + "\n" + rightText;
  }
  if (leftText.length + rightText.length > lw) {
    return leftText + "\n" + " ".repeat(lw - rightText.length) + rightText;
  }
  return (
    leftText + " ".repeat(lw - (leftText.length + rightText.length)) + rightText
  );
};

const parse_float_string = (float_string) => {
  return parseFloat(float_string).toLocaleString("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const printInvoice = (invoice, printer) => {
  let prn = printer.current;
  if (!prn) {
    console.log("Not connected to printer");
    return;
  }

  const fullLine = "_".repeat(LineWidthNormal) + "\n";

  prn.addFeedLine(1);
  prn.addTextAlign(prn.ALIGN_CENTER);
  prn.addTextSize(1, 1);
  prn.addText("Franja Gastro UG\nhaftungsbeschränkt\n");
  prn.addText(fullLine);
  // company information companyRegister
  prn.addTextSize(1, 1);
  prn.addText("Frankfurter Straße 135\n03149 Forst\nDeutschland\n");
  prn.addText(`USt-ID: ${invoice["company"]["companyRegister"]}\n`);
  prn.addText("Handelsregister Nr.: HRB12829\n");
  prn.addText("Tel.: 03562 665274\n");
  prn.addText("Email: info@manitu-forst.de\n");
  prn.addText(fullLine);
  // invoice information
  prn.addText(`Datum: ${invoice["invoice_timestamp"]}\n`);
  prn.addText(`Beleg Nr.: ${invoice["invoice_number"]}\n`);
  prn.addText(fullLine);

  // add products to the invoice
  prn.addTextAlign(prn.ALIGN_LEFT);
  const alphabet = "ABCDEFGHIJK";
  // go through invoice items
  invoice["items"].forEach((article, index) => {
    // add tax rate to taxRates and get the index
    let taxChar = "";
    // search taxRate in taxes list
    invoice["taxes"].forEach((tax, index) => {
      if (tax["tax_taxRate"] === article["item_taxRate"]) {
        taxChar = alphabet[index];
      }
    });
    prn.addTextStyle(false, false, true, prn.COLOR_1);
    prn.addText(taxChar + " " + article["item_name"] + "\n");
    prn.addTextStyle(false, false, false, prn.COLOR_1);
    // parse price, quantity
    const price = parse_float_string(article["item_price"]);
    const total_price = parse_float_string(article["item_total"]);
    const quantity = parseInt(article["item_quantity"]);
    prn.addText(quantity + " x\t\t" + price + " EUR\t " + total_price + "\n");
  });
  prn.addText(fullLine);

  // add total price
  prn.addText(
    "Summe\tEUR\t\t\t " + parse_float_string(invoice["invoice_total"]) + "\n"
  );
  prn.addText(fullLine);

  // add taxes
  prn.addText(
    "Netto\tEUR\t\t\t " + parse_float_string(invoice["invoice_totalNet"]) + "\n"
  );
  invoice["taxes"].forEach((tax, index) => {
    const taxChar = alphabet[index];
    const taxRate = parse_float_string(tax["tax_taxRate"]);
    const tax_gross = parse_float_string(tax["tax_gross"]);
    const tax_total = parse_float_string(tax["tax_tax"]);
    prn.addText(
      taxChar +
        " " +
        taxRate +
        "% von\t " +
        tax_gross +
        "\t\t " +
        tax_total +
        "\n"
    );
  });
  prn.addText(
    "Brutto\tEUR\t\t\t " + parse_float_string(invoice["invoice_total"]) + "\n"
  );
  prn.addText(fullLine);

  // add payment information
  prn.addTextAlign(prn.ALIGN_CENTER);
  prn.addText(`Zahlungsart: ${invoice["invoice_payment"]}\n`);
  prn.addText(`Bezahlt: ${parse_float_string(invoice["invoice_total"])}\n`);
  prn.addFeedLine(1);
  prn.addText(fullLine);

  // add tse information
  prn.addTextAlign(prn.ALIGN_LEFT);
  prn.addTextFont(prn.FONT_C);
  // iterate through tse_data
  invoice["signature"]["tse_data"].forEach((item) => {
    prn.addText(addSpaces(item["label"], item["value"], "small") + "\n");
  });
  prn.addFeedLine(1);

  // cut the paper
  prn.addCut(prn.CUT_FEED);

  prn.send();
};
