import React from "react";

// css
import styles from "./ProductMenu.module.css";

const ProductMenu = (props) => {
  return (
    <div className={styles.productMenu}>
      <div className={`${styles.searchDiv}`}>
        <div
          className={`input-text-wrapper is-with-end-button u-width-full-line u-max-width-500`}
          style={{ "--amount-of-buttons": "1" }}
        >
          <input
            type="search"
            placeholder="Search"
            value={props.searchValue}
            onInput={(e) => props.search(e.target.value)}
          />
          <div className="icon-search" aria-hidden="true"></div>
        </div>
        {/* clear selection */}
        <button
          className={`button ${styles.clearSelectionButton}`}
          onClick={() => props.clearSelection()}
        >
          <span className="icon-x" aria-hidden="true"></span>
          <span className="text">Clear Selection</span>
        </button>
      </div>

      <div className={`tabs ${styles.tabsDiv}`}>
        {/* <button
        className="tabs-button-scroll is-start"
        aria-label="Show items in start side"
        >
        <span className="icon-cheveron-left" aria-hidden="true"></span>
        </button>
        <button
        className="tabs-button-scroll is-end"
        aria-label="Show items in end side"
        >
        <span className="icon-cheveron-right" aria-hidden="true"></span>
      </button> */}
        <ul className={`tabs-list`}>
          {/* iterate over all categories */}
          {props.categories &&
            props.categories.map((category) => {
              return (
                <li className="tabs-item" key={category}>
                  <a
                    className={`tabs-button ${
                      category === props.selectedCategory ? "is-selected" : ""
                    }`}
                    onClick={() => props.changeCategory(category)}
                  >
                    <span className="text">{category}</span>
                  </a>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default ProductMenu;
