import { functions } from "../appwriteConfig";

export const createNewInvoice = async (articles) => {
  const promise = functions.createExecution(
    process.env.REACT_APP_CREATE_INVOICE_FUNCTION_ID,
    // data has to be a string
    JSON.stringify({ articles: articles })
  );
  const result = await promise;
  if (result.statusCode === 200) {
    const response_json = JSON.parse(result.response);
    if (response_json.Status === "success") {
      return response_json.invoice;
    } else {
      return null;
    }
  } else {
    return null;
  }
};
