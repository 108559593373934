import React, { useState, useEffect } from "react";

// css
import styles from "../InvoiceComponent.module.css";

const InvoiceArticle = ({
  article,
  amount,
  index,
  increaseAmount,
  decreaseAmount,
}) => {
  const [price, setPrice] = useState("");

  useEffect(() => {
    // calculate the price
    // convert prize to float with two decimals
    let price_str = (article.price * amount).toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    console.log(price_str);
    // add the currency
    price_str += " €";
    setPrice(price_str);
  }, [article, amount]);

  return (
    <li key={index}>
      <label
        className={`card is-allow-focus u-cursor-pointer ${styles.invoiceItem}`}
      >
        {/* top --> Product name; mid right side --> price; mid left side --> amount */}
        <div className={`card-content ${styles.nameDiv}`}>
          <div className="content">
            <p className={`${styles.invoiceItemName}`}>{article.name}</p>
          </div>
        </div>
        <div className={`card-content ${styles.price_amount_div}`}>
          <div>
            <p className={`${styles.invoiceItemPrice}`}>{price}</p>
          </div>
          <div className={`${styles.invoiceAmount}`}>
            <p>{amount}</p>
          </div>
        </div>
        <div className={`card-content ${styles.buttonsDiv}`}>
          {/* remove button */}
          <div
            className={`button is-danger ${styles.invoiceItemButton} ${styles.removeButton}`}
            onClick={() => decreaseAmount(index)}
          >
            {amount === 1 ? (
              <span
                className={`icon-trash ${styles.buttonIcon}`}
                aria-hidden="true"
              ></span>
            ) : (
              <span
                className={`icon-minus-circle ${styles.buttonIcon}`}
                aria-hidden="true"
              ></span>
            )}
          </div>
          {/* add button */}
          <div
            className={`button is-success ${styles.invoiceItemButton} ${styles.addButton}`}
            onClick={() => increaseAmount(index)}
          >
            <span
              className={`icon-plus ${styles.buttonIcon}`}
              aria-hidden="true"
            ></span>
          </div>
        </div>
      </label>
    </li>
  );
};

export default InvoiceArticle;
