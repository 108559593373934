import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

// css
import styles from "./MenuComponent.module.css";

// utils
import { useAuth } from "../utils/Auth";

// components
import SideBar from "./Sidebar";

const MenuComponent = (props) => {
  const { user, _, logoutUser } = useAuth();
  const navigate = useNavigate();

  const modalRef = useRef(null);
  const [printerIPAddress, setPrinterIPAddress] = useState("192.168.178.69");
  const [printerPort, setPrinterPort] = useState("8008");

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const handleViewSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  });

  const handleLogout = () => {
    logoutUser();
  };

  return (
    <>
      <div className={styles.header}>
        <button
          className={`button ${styles.menuButton}`}
          onClick={() => handleViewSidebar()}
        >
          <i className="icon-menu" aria-hidden="true" size={30}></i>
        </button>

        {/* connect to printer */}
        {/* show status */}
        <div
          className={styles.printerStatus}
          onClick={() => {
            modalRef.current.showModal();
          }}
        >
          {/* circle icon red or green */}
          {props.connectionStatus === "Connected" ? (
            <i
              className={`icon-status-online ${styles.online_icon}`}
              aria-hidden="true"
            ></i>
          ) : (
            <i
              className={`icon-status-offline ${styles.offline_icon}`}
              aria-hidden="true"
            ></i>
          )}

          {/* text */}
          <p>{props.connectionStatus}</p>
        </div>
        {/* connect button */}
        {/* <button
          className={`button ${styles.printerButton}`}
          onClick={() => {
            props.connectPrinter();
          }}
        >
          connect printer
        </button> */}

        {/* logout button */}
        {/* <button
          className={`button ${styles.logoutButton}`}
          onClick={() => handleLogout()}
        >
          logout
        </button> */}
      </div>
      <SideBar isOpen={sidebarOpen} handleLogout={handleLogout} />

      <dialog
        className={`modal ${styles.printerModal}`}
        id="dialog"
        // open={modalOpen}
        ref={modalRef}
      >
        <form className="modal-form" method="dialog">
          <header className="modal-header">
            <div className="u-flex u-main-space-between u-cross-center u-gap-16">
              <div className="avatar is-color-orange is-medium">
                <span className="icon-exclamation" aria-hidden="true"></span>
              </div>
              <h4 className="modal-title heading-level-5">Connect a printer</h4>
              <button
                className="button is-text is-small is-only-icon"
                aria-label="Close modal"
              >
                <span className="icon-x" aria-hidden="true"></span>
              </button>
            </div>
          </header>
          <label className="label">IP Adresse</label>
          <input
            type="text"
            value={printerIPAddress}
            onChange={(e) => setPrinterIPAddress(e.target.value)}
          />
          <br />
          <label className="label">Port</label>
          <input
            type="text"
            value={printerPort}
            onChange={(e) => setPrinterPort(e.target.value)}
          />
          <br />
          <div className={`u-gap-16  ${styles.modalPrinterStatus}`}>
            <div
              className={`${styles.printerStatus}`}
              onClick={() => {
                modalRef.current.showModal();
              }}
            >
              {/* circle icon red or green */}
              {props.connectionStatus === "Connected" ? (
                <i
                  className={`icon-status-online ${styles.online_icon}`}
                  aria-hidden="true"
                ></i>
              ) : (
                <i
                  className={`icon-status-offline ${styles.offline_icon}`}
                  aria-hidden="true"
                ></i>
              )}

              {/* text */}
              <p>{props.connectionStatus}</p>
            </div>
            <button
              className={`button is-secondary ${styles.printerButton}`}
              onClick={(e) => {
                e.preventDefault();
                props.connectPrinter(printerIPAddress, printerPort);
              }}
            >
              connect
            </button>
          </div>
        </form>
      </dialog>
    </>
  );
};

export default MenuComponent;
