import React, { useState, useEffect } from "react";

// components
import ProductMenu from "./ProductComponents/ProductMenu";
import ProductCard from "./ProductComponents/ProductCard";

// css
import styles from "./ProductComponent.module.css";

const ProductComponent = (props) => {
  // iterate over all products and sort them by category
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [imageNames, setImageNames] = useState({});
  const [productsByCategory, setProductsByCategory] = useState({});
  const [actualProducts, setActualProducts] = useState([]); // products that are displayed
  const [favorites, setFavorites] = useState([]); // all favorite products
  const [searchValue, setSearchValue] = useState(""); // value of the search input

  const [categories, setCategories] = useState([
    "Biere",
    "Longdrinks",
    "Weine",
    "Alkoholfreie Getränke",
    "Schnäpse",
    "Cocktails",
    "Heiße Getränke",
    "Speisen",
    "Whisk(e)y",
    "Rum",
  ]);

  useEffect(() => {
    // iterate over all products and search the right image
    let imageNamesDict = {};
    let productsByCategoryDict = {};
    Object.keys(props.products).forEach((key) => {
      const product = props.products[key];
      // search the product image
      // iterate over all images and search an image where all parts are
      // included in the product name
      let imageParts = [];
      for (let i = 0; i < props.imageNames.length; i++) {
        // remove the file extension and split the name at the '+'
        imageParts = props.imageNames[i].split(".")[0].split("+");
        let found = true;
        for (let j = 0; j < imageParts.length; j++) {
          if (!product.name.includes(imageParts[j])) {
            found = false;
            break;
          }
        }
        if (found) {
          // add the key: imageName to the imagesNamesDict
          imageNamesDict[product.id] = props.imageNames[i];
          break;
        }
      }
      // add the product to the right category (the category will not change during runtime)
      // check for uncategorized products
      if (!product.category) {
        product.category = "Uncategorized";
      }
      if (productsByCategoryDict[product.category]) {
        productsByCategoryDict[product.category].push(product.id);
      } else {
        productsByCategoryDict[product.category] = [product.id];
      }
    });
    // sort the products by category
    Object.keys(productsByCategoryDict).forEach((key) => {
      productsByCategoryDict[key].sort((a, b) => {
        return props.products[a].name.localeCompare(props.products[b].name);
      });
    });

    // get all categories
    let product_categories = Object.keys(productsByCategoryDict);
    // get categories that appear in product_categories but not in categories
    let new_categories = product_categories.filter(
      (category) => !categories.includes(category)
    );
    // add the new categories to categories
    setCategories([...categories, ...new_categories]);
    setImageNames(imageNamesDict);
    setProductsByCategory(productsByCategoryDict);

    // get the favorites
    let favorites = [];
    Object.keys(props.products).forEach((key) => {
      if (props.products[key].favorite) {
        favorites.push(props.products[key].id);
      }
    });
    setFavorites(favorites);
    setActualProductsByCategory(favorites);
    // setFavoritesSelected(true);
  }, []);

  useEffect(() => {
    // check for new favorites
    let favorites = [];
    Object.keys(props.products).forEach((key) => {
      if (props.products[key].favorite) {
        favorites.push(props.products[key].id);
      }
    });
    setFavorites(favorites);
    // if selectedCategory is null, display all favorites
    if (!selectedCategory && !searchValue) {
      setActualProductsByCategory(favorites);
      // setFavoritesSelected(true);
    }
  }, [props.products]);

  const setActualProductsByCategory = (product_ids) => {
    // sort product_ids by name
    product_ids.sort((a, b) => {
      return props.products[a].name.localeCompare(props.products[b].name);
    });

    // set the actual products by category
    let productsByCategoryDict = {};
    product_ids.forEach((product_id) => {
      if (productsByCategoryDict[props.products[product_id].category]) {
        productsByCategoryDict[props.products[product_id].category].push(
          product_id
        );
      } else {
        productsByCategoryDict[props.products[product_id].category] = [
          product_id,
        ];
      }
    });
    setActualProducts(productsByCategoryDict);
  };

  // useEffect(() => {
  //   // check if we have various categories in the actual products
  //   let categories = [];
  //   actualProducts.forEach((product_id) => {
  //     if (categories.length > 1) {
  //       return;
  //     }
  //     categories.push(props.products[product_id].category);
  //   });
  //   if (categories.length > 1) {
  //     setMultipleCategories(true);
  //   } else {
  //     setMultipleCategories(false);
  //   }
  // }, [actualProducts, props.products]);

  const changeCategory = (category) => {
    setSelectedCategory(category);
    setActualProductsByCategory(productsByCategory[category]);
  };

  const clearSelection = () => {
    setSelectedCategory(null);
    setSearchValue("");
    setActualProductsByCategory(favorites);
    // setFavoritesSelected(true);
  };

  const search = (value) => {
    setSearchValue(value);
    // filter the products
    let filteredProducts = [];
    if (value && value.length > 1) {
      // search for the value in the product name
      Object.keys(props.products).forEach((key) => {
        const product = props.products[key];
        if (product.name.toLowerCase().includes(value.toLowerCase())) {
          filteredProducts.push(product.id);
        }
      });
    } else if (selectedCategory) {
      filteredProducts = productsByCategory[selectedCategory];
    } else {
      filteredProducts = favorites;
    }
    setActualProductsByCategory(filteredProducts);
  };

  return (
    <div className={styles.parentContainer}>
      {/* check if categories are loaded */}
      {/* {Object.keys(productsByCategory).length && (
      <> */}
      <ProductMenu
        categories={categories}
        selectedCategory={selectedCategory}
        changeCategory={changeCategory}
        clearSelection={clearSelection}
        search={search}
        searchValue={searchValue}
      />
      <div className={styles.productContainer}>
        {/* iterate over all keys and values from actualProducts object */}
        {actualProducts && (
          <>
            {/* iterate over categories to get the right order */}
            {categories.map((cat) => {
              // check if the category is in the actualProducts
              if (actualProducts[cat]) {
                return (
                  <>
                    {/* check if we have multiple categories */}
                    {Object.keys(actualProducts).length > 1 && (
                      <div className={styles.categoryTitle}>
                        <p>{cat}</p>
                      </div>
                    )}
                    <ul
                      className={`grid-box ${styles.productGrid}`}
                      style={{
                        "--p-grid-item-size": "10em",
                        "--p-grid-item-size-small-screens": "10rem",
                        "--grid-gap": "0.5rem",
                      }}
                      key={cat}
                    >
                      {actualProducts[cat].map((product_id) => {
                        return (
                          <ProductCard
                            product={props.products[product_id]}
                            imageName={imageNames[product_id]}
                            key={product_id}
                            addToInvoice={props.addToInvoice}
                          />
                        );
                      })}
                    </ul>
                  </>
                );
              }
            })}
          </>
        )}
      </div>
    </div>
  );
};

// {actualProducts &&
//   actualProducts.map((product_id) => {
//     return (
//       <ProductCard
//         product={props.products[product_id]}
//         imageName={imageNames[product_id]}
//         key={product_id}
//         addToInvoice={props.addToInvoice}
//       />
//     );
//   })}

export default ProductComponent;
