import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// components
import { AuthProvider } from "./utils/Auth";
import Login from "./pages/Login";
import BaseComponent from "./components/BaseComponent";
import PrivateRoute from "./utils/PrivateRoute";

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoute />}>
            <Route
              path="/invoice"
              element={<BaseComponent content="invoice" />}
            />
            <Route path="/" element={<BaseComponent content="invoice" />} />
            <Route
              path="/images"
              element={<BaseComponent content="images" />}
            />
            <Route
              path="/favorites"
              element={<BaseComponent content="favorites" />}
            />
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
